import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionHead } from '../components/section-header'
import { AssetImage } from '../components/image'
import SvgThinRightArrow from '../generated/svg/thin-right-arrow'
import { pageMaxWidth } from '../styles/page-max-width'
import { Animate } from '../components/animate'
import { AnimateArrow } from '../components/animate-arrow'
import { productSku } from '../product-sku'
import { skuToUrl } from '../utils/sku-to-url'

const Outer = styled.div``

const Inner = styled.div`
  ${pageMaxWidth}
`

const HeadContainer = styled.div``

const ItemContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 40rem){
  }
`

const Item = styled.a`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #F6F6F6;
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  img {
    transition: transform 0.4s;
    display: block;
    width: 100%;
    height: auto;
  }
  ${Item}:hover & img {
    transform: scale(1.05);
  }
`

const Badge = styled.div`
  position: absolute;
  font-family: var(--en-font);
  line-height: normal;
  width: 6rem;
  height: 6rem;
  top: 2rem;
  left: 2rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20rem;
  color: white;
  text-align: center;
  /* background-image: linear-gradient(to right bottom, #B7CEB4, #BFC2C7, #C6B7D8); */
  box-sizing: border-box;
  padding-top: 0.5rem;

  background-color: #d5c192;
  color: white;
  font-size: 0.8rem;
  padding-top: 0;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    width: 5rem;
    height: 5rem;
    top: 1rem;
    left: 1rem;
  }
`

const TextContainer = styled.div`
  padding: 4.375rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 40rem){
    padding: 1.75rem;
  }
`

const Text1 = styled.div`
  font-weight: 700;
  @media (max-width: 40rem){
    line-height: 1.603124976158142;
  }
`

const Text2 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 1.5rem;
  @media (max-width: 40rem){
    line-height: 1.7142857142857142;
    margin-top: 1rem;
  }
`

const Note = styled.div`
  font-size: 0.75em;
  @media (max-width: 40rem){
    font-size: 0.5625rem;
    line-height: normal;
    margin-top: 1rem;
  }
`

const ViewMoreButton = styled.a`
  font-family: var(--serif-en-font);
  font-weight: 400;;
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-items: baseline;
  transition: opacity 0.4s 0.2s;
  opacity: 0;
  .animate & {
    opacity: 1;
  }
  @media (max-width: 40rem){
    margin-top: 2.5rem;
    font-size: 0.875rem;
    line-height: normal;
    letter-spacing: 0.08em;
  }
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
    transform: translateX(-1rem);
    transition: transform ;
    .animate & {
      transform: none;
    }
  }
  &:hover svg {
    transition-duration: 0.4s;
    transform: translateX(0.5rem);
  }
`

type TopSpecialSetSectionProps = {

}
export const TopSpecialSetSection: FunctionComponent<TopSpecialSetSectionProps> = () => {
  return <Outer>
    <Inner>
      <HeadContainer>
        <SectionHead>Special Set</SectionHead>
      </HeadContainer>
      <ItemContainer>
        <Item href={skuToUrl(productSku.skinCycleMethod3PieceSet.single)}>
          <ImageContainer>
            <AssetImage src="top-09.png"/>
          </ImageContainer>
          <TextContainer>
            <Text1>
            スキンサイクルメソッド3点セット
            </Text1>
            <Text2>
              3日周期のルーティーンでスキンサイクルメソッドが完成するピールケアセラム、レチノダーマクリーム、ナイアシナグロージェルの3点セット。
            </Text2>
            <Animate target="child">
              <ViewMoreButton>
                VIEW MORE
                <AnimateArrow>
                  <SvgThinRightArrow />
                </AnimateArrow>
              </ViewMoreButton>
            </Animate>
          </TextContainer>
        </Item>
        {/* <Item href={skuToUrl(productSku.freeSelect.single)}>
          <ImageContainer>
            <AssetImage src="top-08.png"/>
          </ImageContainer>
          <TextContainer>
            <Text1>
              フリーセレクトセット
            </Text1>
            <Text2>
              SKN REMED(スキンリメド)すべてのアイテムの中から、種類やグレードを自由に選ぶならこちら。<br />
            <Note>※一部商品は同意書の記入、または医師とのカウンセリングが必要です。</Note>
            </Text2>
            <Animate target="child">
              <ViewMoreButton>
                VIEW MORE
                <AnimateArrow>
                  <SvgThinRightArrow />
                </AnimateArrow>
              </ViewMoreButton>
            </Animate>
          </TextContainer>
        </Item> */}
      </ItemContainer>
    </Inner>
  </Outer>
}