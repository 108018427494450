import React, { FunctionComponent, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { AssetImage } from '../components/image'
import SvgTopGraph04 from '../generated/svg/top-graph-04'
import SvgSetCycle01 from '../generated/svg/set-cycle-01'
import SvgSetCycle02 from '../generated/svg/set-cycle-02'
import SvgRightArrowHead from '../generated/svg/right-arrow-head'
import SvgTopGraph03 from '../generated/svg/top-graph-03'
import { SectionHead } from '../components/section-header'
import { pageMaxWidth } from '../styles/page-max-width'
import { Animate } from '../components/animate'
import { AnimateStep } from '../components/animate-step'
import { Dbr } from '../components/br'

const Outer = styled.div`

  display: grid;
  grid-template-columns: 1fr var(--page-margin) minmax(0 ,calc(var(--page-max-width) - var(--page-margin) * 2)) var(--page-margin) 1fr;
  @media (max-width: 40rem){
    display: block;
  }
`

const Inner = styled.div`
  background-color: #F4F4F4;
  position: relative;
  grid-column: 3 / span 3;
  display: grid;
  grid-template-columns: subgrid;
  padding-bottom: 8rem;

  --left-margin: 5rem;
  --right-margin: calc((100% - var(--page-max-width)) / 2 + var(--page-margin));
  @media (max-width: 40rem){
    ${pageMaxWidth}
    display: block;
    padding-bottom: 5rem;
  }
`

const SectionHeadContainer = styled.div`
  position: absolute;
  top: -1.25rem;
  left: 0;
  @media (max-width: 40rem){
    ${pageMaxWidth}
  }
`

const Section1 = styled.div`
  display: flex;
  margin-top: 6rem;
  padding-left: var(--left-margin);
  gap: 6rem;
  grid-column: 1 / span 2;
  @media (max-width: 60rem){
    flex-direction: column;
    gap: 2rem;
  }
  @media (max-width: 40rem){
    padding-left: 0;
    padding-top: 3rem;
  }
`

const Section1Inner = styled.div`
  min-width: max-content;
  @media (max-width: 60rem){
    min-width: auto;
  }
`

const Text1 = styled.div`
  font-size: 1.375rem;
  line-height: normal;
  font-weight: 700;
  @media (max-width: 40rem){
    line-height: 1.9375;
    font-size: 1rem;
  }
`

const Text2 = styled.div`
  font-size: 0.875rem;
  line-height: 2.4;
  margin-top: 2rem;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 2.2857142857142856;
    margin-top: 1rem;
  }
  br + br {
    display: block;
    height: 2em;
    @media (max-width: 40rem){
      display: none;
    }
  }
`

const ImageContainer1 = styled.div`
  position: relative;
  @media (max-width: 60rem){
    order: -1;
  }
  @media (max-width: 40rem){
    margin-left: calc(var(--side-margin) * -1);
    margin-right: calc(var(--side-margin) * -1);
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  svg {
    display: block;
    width: 80%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 40rem){
      width: 90%;
    }
    path[stroke="#fff"] {
      transition: stroke-dashoffset;
      stroke-dasharray: var(--length);
      stroke-dashoffset: var(--length);
    }
  }
  .animate svg path[stroke="#fff"] {
    stroke-dashoffset: 0;
    transition-duration: 1s;
    transition-delay: 0.4s;
  }

`

const Section2 = styled.div`
  padding-left: var(--left-margin);
  padding-right: var(--right-margin);
  box-sizing: border-box;
  margin-top: 8rem;
  grid-column: 1;
  display: flex;
  @media (max-width: 40rem){
    padding-left: 0;
    padding-right: 0;
    margin-top: 4rem;
    flex-direction: column;
  }
`

const Section2Inner = styled.div`

`

const Text3 = styled.div`
  font-size: 1.5rem;
  line-height: 0.9375;
  font-family: var(--en-font);
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: 1.125rem;
  }
`

const Text4 = styled.div`
  font-size: 0.875rem;
  line-height: 2.4;
  margin-top: 2rem;
  @media (max-width: 40rem){
    margin-top: 1.5rem;
  }
  small {
    display: block;
    font-size: 0.8em;
    margin-top: 1rem;
  }
`

const CycleContainer = styled.div`
  padding-left: var(--left-margin);
  padding-right: var(--right-margin);
  box-sizing: border-box;
  grid-column: 1;
  flex: 0 0 auto;
  @media (max-width: 40rem){
    overflow-y: scroll;
    padding-left: var(--page-margin);
    padding-right: var(--page-margin);
    margin-left: calc(var(--page-margin) * -1);
    margin-right: calc(var(--page-margin) * -1);
    margin-top: 2rem;
  }
`

const CycleInner = styled.div`
  box-sizing: border-box;
  position: relative;
  min-width: fit-content;
  @media (max-width: 40rem){
    gap: 2rem;
  }
`

const CycleItem = styled.div`
  
`

const CycleTitle = styled.div`
  font-size: 1.375rem;
  letter-spacing: 0.06em;
  font-family: var(--en-font);
  font-weight: 700;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  @media (max-width: 40rem){
    font-size: 1rem;
  }
  &::after {
    content: '';
    display: block;
    height: 1px;
    background: linear-gradient(to right, currentColor, transparent);
    flex: 1 1 0;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: transform 0.4s 0.4s;
    .animate & {
      transform: none;
    }
  }
`

const CycleGraphContainer = styled.div`
  margin-top: 2rem;
  svg {
    display: block;
    width: 100%;
    height: auto;
    max-width: 34rem;
    margin-inline: auto;
    @media (max-width: 40rem){
      min-width: 18rem;
      max-width: 30rem;
    }
  }
`

const CycleArrowOuter = styled.div`
  width: 100vw;
  max-width: 25rem;
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 40rem){
    max-width: 8rem;
  }
`

const CycleArrowContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 0.5rem;
  svg {
    display: block;
    height: 0.75rem;
    width: auto;
    margin-left: auto;
    opacity: 0;
    transition: opacity 0.4s 0.4s;
    .animate & {
      opacity: 1;
    }
    @media (max-width: 40rem){
      height: 0.5rem;
    }
  }
  &::after {
    content: '';
    display: block;
    top: 50%;
    left: 0;
    height: 1px;
    background-color: currentColor;
    transform: translateY(-50%);
    position: absolute;
    transition: width 0.4s;
      width: 0;
    .animate & {
      width: 100%;
    }
  }
`

const CycleArrowLabel = styled.div`
  font-size: 1.25rem;
  font-family: var(--en-font);
  text-align: center;
  @media (max-width: 40rem){
    font-size: 0.625rem;
  }
`

const Section3 = styled.div`
  margin-top: 8rem;
  grid-column: 1;
  padding-left: var(--left-margin);
  display: flex;
  gap: 6rem;
  @media (max-width: 40rem){
    
  }
  @media (max-width: 40rem){
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    gap: 0;
  }
`

const Section3Inner = styled.div`
  max-width: 32.125rem;
`

const GraphContainer1 = styled.div`
  flex: 1 1 0;
  min-width: 50%;
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Section4 = styled.div`
  margin-top: 8rem;
  grid-column: 1;
  border: 1px solid currentColor;
  padding: 4rem;
  display: flex;
  gap: 2.5rem;
  margin-right: 2rem;
  margin-left: calc(var(--left-margin) + 2rem);
  @media (max-width: 60rem){
    margin-left: calc(var(--left-margin));
    margin-right: 0;
    padding: 2rem;
  }
  @media (max-width: 40rem){
    flex-direction: column;
    margin-left: 0;
  }
`

const ImageContainer2 = styled.div`
  height: 100%;
  max-height: 100%;
  img {
    display: block;
    height: auto;
    width: 12rem;
  }
  @media (max-width: 40rem){
    display: flex;
    justify-content: center;
  }
`

const Section4Inner = styled.div`
`

const Text5 = styled.div`
  font-size: 1rem;
  font-weight: 700;
`

const Text6 = styled.div`
  font-size: 0.8125rem;
  line-height: 2.1538461538461537;
  margin-top: 0.75rem;
  @media (max-width: 40rem){
    br {
      display: none;
    }
  }
`

const Text7 = styled.div`
  font-size: 0.6875rem;
  line-height: 1.7272727272727273;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid currentColor;
`

const Note = styled.div`
  font-size: 0.8em;
  margin-top: 1.5em;
  @media (max-width: 40rem){
    font-size: 0.5625rem;
  }
`

type TopConceptSectionProps = {

}
export const TopConceptSection: FunctionComponent<TopConceptSectionProps> = () => {
  const graphRef = useRef<SVGSVGElement>(null)
  useEffect(() => {
    const graph = graphRef.current
    if(graph){
      const circles = graph.querySelectorAll<SVGPathElement>('path[stroke="#fff"')
      circles.forEach(circles => {
        circles.style.setProperty('--length', circles.getTotalLength().toString())
      })
    }
  }, [])
  return <Outer>
    <Inner>
      <SectionHeadContainer>
        <SectionHead>
          Concept
        </SectionHead>
      </SectionHeadContainer>
      <Section1>
        <Section1Inner>
          <Text1>
            ドクターが設計した<br />
            効果的に使えるスキンケア理論
          </Text1>
          <Text2>
            2種類のスキンケア理論を使い<Dbr />
            あなたのお肌に合わせたグレードアップを実現。<Dbr />
            クリニックの美容施術にも採用されているドクターズスキンケアです。<Dbr />
            <br />
            あなたの今の肌状態、目標に合わせた<br />
            スキンケアを提案いたします。
          </Text2>
        </Section1Inner>
        <ImageContainer1>
          <AssetImage src="top-05.png" />
          <Animate target="child">
            <SvgTopGraph04 ref={graphRef} />
          </Animate>
        </ImageContainer1>
      </Section1>
      <Section2 id="method">
        <Section2Inner>
          <Text3>
            SKIN CYCLE METHOD
          </Text3>
          <Text4>
            肌荒れを起こしにくく、より効果的にレチノール<sup>※</sup>を使う方法としてアメリカを中心に世界的に流行しているスキンケア方法を、<br />
            日本人の肌に合うようにアレンジしたのがSKN REMEDの”Skin Cycle Method”です。<br />
            <Note>※ 整肌成分</Note>
          </Text4>
        </Section2Inner>
        <CycleContainer>
          <CycleInner>
            <CycleItem>
              <CycleTitle>
                BASIC CYCLE
              </CycleTitle>
              <CycleGraphContainer>
                <SvgSetCycle01 />
              </CycleGraphContainer>
            </CycleItem>
          </CycleInner>
        </CycleContainer>
      </Section2>
      <Section3>
        <Section3Inner>
          <Text3>
            SKIN GRADE PROGRAM
          </Text3>
          <Text4>
            SKN REMED(スキンリメド)では、自分のグレードに合わせて段階的に設計された成分種類・濃度システムをご用意。<Dbr />
            成分濃度、肌の悩み、肌の耐性といった3つの状態を見極めて、段階的にスキンケアグレードを上げていくことができるので、<Dbr />
            肌に無理なく効果的なケアに取り組めます。<br />
          </Text4>
        </Section3Inner>
        <GraphContainer1>
          <AnimateStep>
            <SvgTopGraph03 />
          </AnimateStep>
        </GraphContainer1>
      </Section3>
      <Section4>
        <ImageContainer2>
          <AssetImage src="top-06.png" />
        </ImageContainer2>
        <Section4Inner>
          <Text5>
            皮膚の専門家 大久保 里紗 監修
          </Text5>
          <Text6>
            「スキンサイクリングメソッド」と、「スキングレードプログラム」とは、<Dbr />
            「高濃度ケアを使ってみたいけれど、肌荒れ（ダウンタイム）が心配」<Dbr />
            そんな美容皮膚科の現場でのお客様の声を元にして作った理論です。<br />
            <br />
            レチノール<sup>※1</sup>の効果はしっかりと感じながらも、肌荒れを起こしづらいスキンケア方法を<Dbr />
            是非試してみてください。
            <Note>※1 整肌成分</Note>
          </Text6>
        </Section4Inner>
      </Section4>
    </Inner>
  </Outer>
}